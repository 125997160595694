import { extendTheme } from "@chakra-ui/react";

const theme = {
  fonts: {
    heading: `'Open Sans', sans-serif`,
    body: `'Open Sans', sans-serif`,
  },
  colors: {},
  breakpoints: {
    base: "0px",
    sm: "320px",
    //md: "320px",
    lg: "960px",
    //xl: "1200px",
  },
};

export default extendTheme(theme);
